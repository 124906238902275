<template>
    <div>
        <form @submit.prevent="updateRecord">

            <!-- paste from hq below here -->

            <div class="">
                <h1>Settings</h1>
                <div class="flex colx4 wrap">
                    <div><Input
                        type="text"
                        name="category"
                        id="category"
                        placeholder="Category"
                        v-model="record_data.category"
                        label="Category"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="setting"
                        id="setting"
                        placeholder="Setting"
                        v-model="record_data.setting"
                        label="Setting"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="levels"
                        id="levels"
                        placeholder="Levels"
                        v-model="record_data.levels"
                        label="Levels"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="default_value"
                        id="default_value"
                        placeholder="Default Value"
                        v-model="record_data.default_value"
                        label="Default Value"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="value_options"
                        id="value_options"
                        placeholder="Value Options"
                        v-model="record_data.value_options"
                        label="Value Options"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="label"
                        id="label"
                        placeholder="Label"
                        v-model="record_data.label"
                        label="Label"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="data_type"
                        id="data_type"
                        placeholder="Data Type"
                        v-model="record_data.data_type"
                        label="Data Type"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="min_val"
                        id="min_val"
                        placeholder="Min Val"
                        v-model="record_data.min_val"
                        label="Min Val"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="max_val"
                        id="max_val"
                        placeholder="Max Val"
                        v-model="record_data.max_val"
                        label="Max Val"
                        :disabled="!isEditable"
                    /></div>
                    <!-- always value -->
                    <div><Input
                        type="text"
                        name="max_val"
                        id="max_val"
                        placeholder="Max Val"
                        v-model="record_data.value"
                        label="Max Val"
                        :disabled="!isEditable"
                    /></div>
                </div>

                <!--form_extra-->
            </div>


            <ConfirmButton @click="updateRecord()"></ConfirmButton>
        </form>
    </div>
</template>
<script>
    const api_root = 'settings'

    export default {
        name: 'SettingDetail',
        props: {
            record_id: [String, Number],
        },
        data() {
            return {
                record_data : {
                    settings_id : 0,
                    category : '',
                    setting : '',
                    levels : '',
                    default_value : '',
                    value_options : '',
                    label : '',
                    data_type : '',
                    min_val : '',
                    max_val : '',
                    value : ''

                },
                isEditable : 1,
                record_actions : {
                    getRecordDetails: (id) => `/${api_root}/${id}`,
                    createNewRecord: () => `/${api_root}`,
                    updateRecord: (id) => `/${api_root}/${id}`,
                }
            }
        },
        methods: {
            async init() {
                if (!(this.record_id === 'new')) {
                    const res = await this.$api.get(this.record_actions.getRecordDetails(this.record_id))

                    if (res.status === 404 || res.status === 500) {
                        this.$toasted.error('Could not find record')
                        return this.$router.replace({ name: 'NotFound' })
                    } else {
                        this.record_data = res.data.info[0]
                    }

                }
            },
            async updateRecord(e) {
                e.preventDefault()
                const isValid = await this.validateForm()

                if (isValid) {
                    try {
                        await this.$api.put(this.record_actions.updateRecord(this.record_id), this.record_data)
                        this.$toasted.success('Successfully updated Record.')
                    } catch (err) {
                        this.$toasted.error('Failed to update Record. Please try again later.')
                    }
                }
            },
            async validateForm() {
                const isValid = 1 // await this.$refs.form.validate()

                if (!isValid) {
                    this.$toasted.error('Please fill out all required fields.')
                }

                return isValid
            },
        },
        async created() {
            await this.init()
        },
        watch: {
            async $route() {
                await this.init()
            },
        },
    }
</script>
